import { DatatableSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import createTablePreferenceSlice from "./utils/tablePreferencesSliceCreator";

const initialState: DatatableSliceStateType = {
    hiddenColumns: [],
    rowsPerPage: 25,
    sortBy: [{ desc: true, id: "created_at" }],
    columnsOrder: [
        "clients.name",
        "order",
        "notification_url",
        "attempts",
        "created_at",
        "",
    ],
};

export const {
    reducer: failedNotificationsTablePreferenceSliceReducer,
    actions: failedNotificationsTablePreferenceActions,
} = createTablePreferenceSlice("failedNotifications", initialState);
