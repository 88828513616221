import { ClientTreeSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientTree } from "@paylands-frontend/api/dist/ClientTree";
import { Api } from "../utils/Api";

const initialState: ClientTreeSliceStateType = {
    data: null,
    loading: false,
    errors: null,
};

export const getClientTree = createAsyncThunk("clientTree", async () => {
    const response = await new ClientTree(Api).fetch().catch((error) => {
        return error.response.data;
    });
    return response.data.clients;
});

export const clientTreeSlice = createSlice({
    name: "clientTree",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClientTree.pending, (state) => {
                state.loading = true;
            })
            .addCase(getClientTree.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getClientTree.rejected, (state, action) => {
                state.loading = false;
                state.data = null;
                state.errors = action.payload;
            });
    },
});

export default clientTreeSlice.reducer;
