import { DatatableSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import createTablePreferenceSlice from "./utils/tablePreferencesSliceCreator";

const initialState: DatatableSliceStateType = {
    hiddenColumns: ["order_uuid", "order_operative"],
    rowsPerPage: 25,
    sortBy: [{ desc: true, id: "order_created_at" }],
    columnsOrder: [
        "order_uuid",
        "client_name",
        "destination",
        "order_amount",
        "moto_payment_status",
        "orders.paid",
        "order_operative",
        "order_created_at",
        "detail",
        "goto",
        "is_certified",
        "payment_url",
    ],
};

export const {
    reducer: ordersMotoTablePreferenceSliceReducer,
    actions: ordersMotoTablePreferenceActions,
} = createTablePreferenceSlice("ordersMoto", initialState);
