import React from "react";
import store from "../../store";

type AccessControlProps = {
    allowedPermissions: string[];
    allowedRoles?: number[];
    permissive?: boolean;
    children?: React.ReactNode;
    renderNoAccess: () => JSX.Element;
};
/**
 * A function that checks if user has any of provided permissions
 * @param  {string[]} allowedPermissions  - A list of permissions user has to have in order to view the component.
 * @param {number[] | undefined} allowedRoles - A list of roles allowed to view the component.
 * @param {boolean | undefined} permissive - Determines if permissions check is strict or not.
 * @returns {boolean} - A boolean that specifics if user has any of provided permissions.
 */
export const checkPermissions = (
    allowedPermissions: string[],
    allowedRoles?: number[],
    permissive?: boolean
): boolean => {
    const auth = store.getState().auth;
    const userPermissions = auth.scope || [""];
    const userRole = auth.user?.group_id;
    let roleIsAllowed = true;
    if (allowedPermissions.length === 0 || allowedPermissions[0] === "") {
        if (!allowedRoles || allowedRoles.length === 0) {
            return true;
        } else {
            roleIsAllowed = userRole ? allowedRoles.includes(userRole) : false;
            return roleIsAllowed;
        }
    }

    if (allowedRoles && allowedRoles.length > 0) {
        roleIsAllowed = userRole ? allowedRoles.includes(userRole) : false;
    }

    if (permissive) {
        return (
            userPermissions.some((permission) =>
                allowedPermissions.includes(permission)
            ) && roleIsAllowed
        );
    }
    return (
        allowedPermissions.every((permission) =>
            userPermissions.includes(permission)
        ) && roleIsAllowed
    );
};
/**
 * Wrapping component that adds access control check. If user access controll list includes any of provided to the component alowed permission
 * it will render the child element, other way nothing/ component provided as renderNoAccess will be rendered.
 *
 * @param {string[]} props.allowedPermissions - A list of permissions user has to have in order to view the component.
 * @param {number[]} props.allowedRoles - A list of roles allowed to view the component.
 * @param {boolean | undefined} permissive - Determines if permissions check is strict or not.
 * @param {() => JSX.Element} props.renderNoAccess - A component to be rendered if user has no permissions. If not provided - nothing will be rendered.
 * @param {React.ReactNode} props.children - A component to be rendered if user has permissions to view it.
 * @returns
 */
const AccessControl = ({
    allowedPermissions,
    allowedRoles,
    permissive,
    children,
    renderNoAccess,
}: AccessControlProps): JSX.Element => {
    const permitted = checkPermissions(
        allowedPermissions,
        allowedRoles,
        permissive
    );
    if (permitted) {
        return <>{children}</>;
    }
    return renderNoAccess();
};
AccessControl.defaultProps = {
    allowedPermissions: [],

    renderNoAccess: () => null,
};

export default AccessControl;
