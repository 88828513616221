import { createSlice } from "@reduxjs/toolkit";
import { switchDashboardSliceStateType } from "../Interfaces/Redux/sliceStateTypes";

const initialState: switchDashboardSliceStateType = {
    migrationDate: ""
};

export const switchDashboardSlice = createSlice({
    name: "switchDashboard",
    initialState,
    reducers: {
        setDate: (state, action) => {
            state.migrationDate = action.payload;
        },
    }}
);

export const { setDate } =
    switchDashboardSlice.actions;
export default switchDashboardSlice.reducer;