import translationEN from "@paylands-frontend/translations/src/en.json";
import translationES from "@paylands-frontend/translations/src/es.json";
import translationPT from "@paylands-frontend/translations/src/pt.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationES,
    },
    pt: {
        translation: translationPT,
    },
};
const languages = ["es", "en", "pt"];

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"],
        },
        whitelist: languages,
        resources,
        fallbackLng: "en",
    });
