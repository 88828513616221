import { DatatableSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import createTablePreferenceSlice from "./utils/tablePreferencesSliceCreator";

const initialState: DatatableSliceStateType = {
    hiddenColumns: ["filename","description","uuid"],
    rowsPerPage: 25,
    sortBy: [{ desc: true, id: "moto_detail_created_at" }],
    columnsOrder: [
        "subject",
        "description",
        "client_name",
        "moto_detail_type",
        "moto_detail_status",
        "filename",
        "payment_count",
        "moto_detail_created_at",
        "moto_detail_expires_at",
        "uuid",
    ],
};

export const {
    reducer: campaignsTablePreferenceSliceReducer,
    actions: campaignsTablePreferenceActions,
} = createTablePreferenceSlice("campaigns", initialState);
