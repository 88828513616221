import { createSlice } from "@reduxjs/toolkit";

import moment from "moment-timezone";
import { FiltersSliceStateType } from "../Interfaces/Redux/sliceStateTypes";

const monthsByDefault = 1;
const daysByDefault = 7;

const initialState: FiltersSliceStateType = {
    orders: {
        endingDate: moment().endOf("day").format(),
        startingDate: moment()
            .subtract(daysByDefault, "days")
            .startOf("day")
            .format(),
        additional: null,
        minAmount: null,
        maxAmount: null,
        externalId: null,
        operative: null,
        serviceUUID: null,
        status: null,
        isSafe: null,
        orderType: null,
        reference: null,
        orderTags: null,
        sourceTypes: null,
        transactionError: null,
        processorID: null,
        authorization: null,
    },

    customers: {
        endingDate: moment().endOf("day").format(),
        startingDate: moment()
            .subtract(monthsByDefault, "month")
            .startOf("day")
            .format(),
        externalId: null,
    },
    campaigns: {
        createdAtEnd: moment().endOf("day").format(),
        createdAtStart: moment()
            .subtract(monthsByDefault, "month")
            .startOf("day")
            .format(),
        description: null,
        expiresAtEnd: null,
        expiresAtStart: null,
        filename: null,
        serviceUUID: null,
        subject: null,
        type: null,
    },
    ordersMoto: {
        createdAtEnd: moment().endOf("day").format(),
        createdAtStart: moment()
            .subtract(monthsByDefault, "month")
            .startOf("day")
            .format(),
        additional: null,
        certified: null,
        destination: null,
        detailUUID: null,
        externalID: null,
        isPaid: null,
        maxAmount: null,
        minAmount: null,
        operative: null,
        orderType: null,
        paymentStatus: null,
        serviceUUID: null,
    },
    failedNotifications: {
        endingDate: moment().endOf("day").format(),
        startingDate: moment()
            .subtract(monthsByDefault, "month")
            .startOf("day")
            .format(),
        uuid: null,
    },
    devices: {
        identifier: null,
        service: null,
        terminal_id: null,
        communication_type: null,
        active: null,
        configured: null,
        created_at_from: null,
        created_at_to: null,
        updated_at_from: null,
        updated_at_to: null,
    },
};

const formatDate = (date: moment.Moment, timezone: string) => {
    const adjustedDate = moment(date.format("YYYY-MM-DDTHH:mm:ss")).tz(
        timezone
    );
    const formattedDate = adjustedDate.format("YYYY-MM-DDTHH:mm:ssZ");
    return formattedDate;
};

export const FiltersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setFiltersDatesTimezone: (state, action) => {
            Object.keys(state).map((entity) => {
                const entityState =
                    state[entity as keyof FiltersSliceStateType];
                let date: moment.Moment;

                if ("startingDate" in entityState) {
                    date = moment.parseZone(entityState["startingDate"]);
                    entityState["startingDate"] = formatDate(
                        date,
                        action.payload
                    );
                }
                if ("endingDate" in entityState) {
                    date = moment.parseZone(entityState["endingDate"]);
                    entityState["endingDate"] = formatDate(
                        date,
                        action.payload
                    );
                }
                if ("createdAtStart" in entityState) {
                    date = moment.parseZone(entityState["createdAtStart"]);
                    entityState["createdAtStart"] = formatDate(
                        date,
                        action.payload
                    );
                }
                if ("createdAtEnd" in entityState) {
                    date = moment.parseZone(entityState["createdAtEnd"]);
                    entityState["createdAtEnd"] = formatDate(
                        date,
                        action.payload
                    );
                }
            });
        },
        setOrdersFilters: (state, action) => {
            state.orders = action.payload;
        },
        clearOrdersFilters: (state) => {
            state.orders = initialState.orders;
        },
        setCustomersFilters: (state, action) => {
            state.customers = action.payload;
        },
        clearCustomersFilters: (state) => {
            state.customers = initialState.customers;
        },

        setCampaignsFilters: (state, action) => {
            state.campaigns = action.payload;
        },
        clearCampaignsFilters: (state) => {
            state.campaigns = initialState.campaigns;
        },
        setOrdersMotoFilters: (state, action) => {
            state.ordersMoto = action.payload;
        },
        clearOrdersMotoFilters: (state) => {
            state.ordersMoto = initialState.ordersMoto;
        },
        setFailedNotificationsFilters: (state, action) => {
            state.failedNotifications = action.payload;
        },
        clearFailedNotificationsFilters: (state) => {
            state.failedNotifications = initialState.failedNotifications;
        },
        setDevicesFilters: (state, action) => {
            state.devices = action.payload;
        },
        clearDevicesFilters: (state) => {
            state.devices = initialState.devices;
        },
    },
});
export const {
    setFiltersDatesTimezone,
    setOrdersFilters,
    clearOrdersFilters,
    setCustomersFilters,
    clearCustomersFilters,
    setCampaignsFilters,
    clearCampaignsFilters,
    setOrdersMotoFilters,
    clearOrdersMotoFilters,
    setFailedNotificationsFilters,
    clearFailedNotificationsFilters,
    setDevicesFilters,
    clearDevicesFilters,
} = FiltersSlice.actions;
export default FiltersSlice.reducer;
