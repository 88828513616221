import { CustomThemeOptions } from "@mui/material/styles";

export const PaynoPainOptions: CustomThemeOptions = {
    custom: {
        name: "paynopain",
        support_email: "soporte@paylands.com",
        default_language: "es",
        headName: "Paylands",
    },
    palette: {
        white: {
            main: "#fff",
            contrastText: "#EE1B4E",
        },
        primary: {
            main: "#7C7B9B",
            light: "#D0CEDE",
            dark: "#39386C",
            contrastText: "#fff",
        },
        secondary: {
            main: "#EE1B4E",
            light: "#FAD1DA",
            dark: "#8A2E42",
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#F5624E",
            light: "#B2311F",
            dark: "#B2311F",
            contrastText: "#FFFFFF",
        },
        warning: {
            main: "#F2C536",
            light: "#FFF3CC",
            dark: "#D16C0F",
            contrastText: "#FFFFFF",
        },
        info: {
            main: "#4558FF",
            light: "#D1D1FA",
            dark: "#131088",
            contrastText: "#FFFFFF",
        },
        success: {
            main: "#05A5B2",
            light: "#D2EEEF",
            dark: "#05A5B2",
            contrastText: "#FFFFFF",
        },
        action: {
            active: "#0000008A",
            hover: "#0000000A",
            selected: "#00000014",
            disabled: "#00000042",
            disabledBackground: "#0000001F",
            focus: "#0000001F",
        },
        text: {
            primary: "#303030",
            secondary: "#00000099",
            disabled: "#00000061",
        },
        divider: "#0000001F",
    },
    typography: {
        fontFamily: "Nunito Sans",
        h1: {
            fontSize: "32px",
            fontWeight: "bold",
        },
        h2: {
            fontSize: "24px",
        },
        h3: {
            fontSize: "20px",
        },
        subtitle1: {
            fontSize: "16px",
        },
        subtitle2: {
            fontSize: "14px",
        },
        body1: {
            fontSize: "16px",
        },
        body2: {
            fontSize: "14px",
            transform: "initial",
        },
        button: {
            transform: "uppercase",
        },
        caption: {
            fontSize: "12px",
        },
        overline: {
            fontSize: "12px",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                sizeSmall: {
                    fontWeight: "bold",
                    fontSize: "12px",
                },
                sizeMedium: {
                    fontWeight: "bold",
                    fontSize: "14px",
                },
                sizeLarge: {
                    fontWeight: "bold",
                    fontSize: "14px",
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #EEEEEE",
                    height: "56px",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    background: "#E6F6F7",
                    color: "#024247",
                    "& .MuiAlert-icon": {
                        color: "#05A5B2",
                    },
                },
                standardError: {
                    background: "#FEEFED",
                    color: "#62271F",
                    "& .MuiAlert-icon": {
                        color: "#F5624E",
                    },
                },
                standardInfo: {
                    background: "#E7E9FE",
                    color: "#151653",
                    "& .MuiAlert-icon": {
                        color: "#4558FF",
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1200,
            xl: 1500,
        },
    },
};

export default PaynoPainOptions;
