import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificationSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import { getUserNotifications } from "@paylands-frontend/api/dist/User";
import { Api } from "../utils/Api";

const initialState: NotificationSliceStateType = {
    data: [],
    loading: false,
    errors: null,
};

export const getAllNotifications = createAsyncThunk(
    "notifications/getAll",
    async (userUUID: string) => {
        const response = await new getUserNotifications(Api)
        .fetch(userUUID)
        .catch((error) => {
            return error.response.data;
        });
        return response.data.notifications;
    }
);

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getAllNotifications.rejected, (state, action) => {
                state.loading = false;
                state.data = [];
                state.errors = action.payload;
            })
    },
}); 

export default notificationsSlice.reducer;
