import {
    Action,
    combineReducers,
    configureStore,
    ThunkDispatch,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
    createMigrate,
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    AuthSliceStateType,
    ClientTreeSliceStateType,
    DatatableSliceStateType,
    FiltersSliceStateType,
    NotificationSliceStateType,
    SnackbarSliceStateType,
    switchDashboardSliceStateType,
    UISliceStateType,
    MerchantSliceStateType,
} from "./Interfaces/Redux/sliceStateTypes";
import authSlice from "./Slices/authSlice";
import { campaignsTablePreferenceSliceReducer } from "./Slices/campaignsTablePreferenceSlice";
import customersTablePreferenceSlice from "./Slices/customersTablePreferenceSlice";
import { failedNotificationsTablePreferenceSliceReducer } from "./Slices/failedNotificationsTablePreferenceSlice";
import filtersSlice from "./Slices/filtersSlice";
import migrations from "./Slices/migrations";
import notificationsSlice from "./Slices/notificationsSlice";
import { ordersMotoTablePreferenceSliceReducer } from "./Slices/ordersMotoTablePreferenceSlice";
import ordersTablePreferenceSlice from "./Slices/ordersTablePreferenceSlice";
import snackbarSlice from "./Slices/snackbarSlice";
import switchDashboardSlice from "./Slices/switchDashboardSlice";
import UISlice from "./Slices/UISlice";
import clientTreeSlice from "./Slices/clientTreeSlice";
import merchantSlice from "./Slices/merchantSlice";

const reducers = combineReducers({
    ui: UISlice,
    auth: authSlice,
    clientTree: clientTreeSlice,
    snackbar: snackbarSlice,
    filters: filtersSlice,
    userNotifications: notificationsSlice,
    ordersTablePreference: ordersTablePreferenceSlice,
    customersTablePreference: customersTablePreferenceSlice,
    campaignsTablePreference: campaignsTablePreferenceSliceReducer,
    ordersMotoTablePreference: ordersMotoTablePreferenceSliceReducer,
    failedNotificationsTablePreference:
        failedNotificationsTablePreferenceSliceReducer,
    switchDashboard: switchDashboardSlice,
    merchant: merchantSlice,
});

const persistConfig = {
    key: "root",
    version: 9,
    storage,
    whitelist: [
        "ui",
        "ordersTablePreference",
        "customersTablePreference",
        "campaignsTablePreference",
        "ordersMotoTablePreference",
        "failedNotificationsTablePreference",
        "switchDashboard",
    ],
    blacklist: ["auth", "snackbar", "filters", "clientTree", "merchant"],
    migrate: createMigrate(migrations, {
        debug: process.env.NODE_ENV !== "production",
    }),
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
    devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = (): ThunkAppDispatch =>
    useDispatch<ThunkAppDispatch>();
export const selectAuth = (state: RootState): AuthSliceStateType => state.auth;
export const selectSwitchDashboard = (
    state: RootState
): switchDashboardSliceStateType => state.switchDashboard;
export const selectUI = (state: RootState): UISliceStateType => state.ui;
export const selectSnackbar = (state: RootState): SnackbarSliceStateType =>
    state.snackbar;
export const selectFilters = (state: RootState): FiltersSliceStateType =>
    state.filters;
export const selectUserNotifications = (
    state: RootState
): NotificationSliceStateType => state.userNotifications;
export const selectClientTree = (state: RootState): ClientTreeSliceStateType =>
    state.clientTree;

export const selectOrdersTablePreference = (
    state: RootState
): DatatableSliceStateType => state.ordersTablePreference;
export const selectCustomersTablePreference = (
    state: RootState
): DatatableSliceStateType => state.customersTablePreference;
export const selectCampaignsTablePreference = (
    state: RootState
): DatatableSliceStateType => state.campaignsTablePreference;
export const selectOrdersMotoTablePreference = (
    state: RootState
): DatatableSliceStateType => state.ordersMotoTablePreference;
export const selectFailedNotificationsTablePreference = (
    state: RootState
): DatatableSliceStateType => state.failedNotificationsTablePreference;
export const selectMerchant = (state: RootState): MerchantSliceStateType =>
    state.merchant;
export default store;
