import { createSlice } from "@reduxjs/toolkit";

interface TablePreferenceState {
    hiddenColumns: string[];
    rowsPerPage: number;
    sortBy: { desc: boolean; id: string }[];
    columnsOrder: string[];
}
type TablePreferenceSliceActions = {
    setHiddenColumns: (hiddenColumns: string[]) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    setSortBy: (sortBy: { desc: boolean; id: string }) => void;
    setColumnOrder: (columnsOrder: string[]) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createTablePreferenceSlice = (
    tableName: string,
    initialState: TablePreferenceState
) => {
    const tablePreferenceSlice = createSlice({
        name: `${tableName}TablePreference`,
        initialState,
        reducers: {
            setHiddenColumns: (state, action) => {
                state.hiddenColumns = action.payload;
            },
            setRowsPerPage: (state, action) => {
                state.rowsPerPage = action.payload;
            },
            setSortBy: (state, action) => {
                state.sortBy = action.payload;
            },
            setColumnOrder: (state, action) => {
                state.columnsOrder = action.payload;
            },
        },
    });
    const actions: TablePreferenceSliceActions = {
        setHiddenColumns: tablePreferenceSlice.actions.setHiddenColumns,
        setRowsPerPage: tablePreferenceSlice.actions.setRowsPerPage,
        setSortBy: tablePreferenceSlice.actions.setSortBy,
        setColumnOrder: tablePreferenceSlice.actions.setColumnOrder,
    };

    return { ...tablePreferenceSlice, actions };
};

export default createTablePreferenceSlice;
