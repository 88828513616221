import { createSlice } from "@reduxjs/toolkit";
import { DatatableSliceStateType } from "../Interfaces/Redux/sliceStateTypes";

const initialState: DatatableSliceStateType = {
    hiddenColumns: ["home_phone_number", "work_phone_number"],
    rowsPerPage: 10,
    sortBy: [{ desc: true, id: "created_at" }],
    columnsOrder: [
        "full_name",
        "external_id",
        "mobile_phone_number",
        "email",
        "payment_method",
        "created_at",
        "phone_number",
        "home_phone_number",
        "work_phone_number",
        "client_name",
    ],
};

export const customersTablePreferenceSlice = createSlice({
    name: "customersTablePreference",
    initialState,
    reducers: {
        setCustomersHiddenColumns: (state, action) => {
            state.hiddenColumns = action.payload;
        },
        setCustomersRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setCustomersSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setCustomersColumnOrder: (state, action) => {
            state.columnsOrder = action.payload;
        },
    },
});
export const {
    setCustomersHiddenColumns,
    setCustomersRowsPerPage,
    setCustomersSortBy,
    setCustomersColumnOrder,
} = customersTablePreferenceSlice.actions;
export default customersTablePreferenceSlice.reducer;
