import { CustomThemeOptions, createTheme } from "@mui/material/styles";
export type CustomThemeConfigOptions = {
    name: string;
    support_email: string;
    accent: string;
    primary?: string;
    default_language: "es" | "en" | "pt";
    headName: string;
};
/**
 * Creates custom theme options.
 * @param {Object} options - The options for creating the custom theme.
 * @param {string} options.name - The name of the custom theme.
 * @param {string} options.support_email - The support email for the custom theme.
 * @param {string} options.accent - The accent color for the custom theme.
 * @param {string} [options.primary] - Optional primary color for the custom theme.
 * @returns {CustomThemeOptions} The created custom theme options.
 */
export const createCustomThemeOptions = (
    options: CustomThemeConfigOptions
): CustomThemeOptions => {
    const theme = createTheme();
    const { name, support_email, primary, accent, default_language, headName } =
        options;
    return {
        custom: {
            name: name,
            support_email: support_email,
            default_language: default_language,
            headName: headName,
        },
        palette: {
            white: { main: "#fff", contrastText: accent },
            primary: primary
                ? theme.palette.augmentColor({ color: { main: primary } })
                : {},
            secondary: theme.palette.augmentColor({
                color: { main: accent },
            }),
        },
    };
};
