import { MigrationManifest } from "redux-persist";
import { RootState } from "../store";

const migrations: MigrationManifest = {
    //initial
    //@ts-expect-error migrations are poorly typed
    1: (state: RootState) => ({
        ...state,
        ordersTablePreference: {
            ...state.ordersTablePreference,
            sortBy: [{ desc: true, id: "created" }],
        },
    }),

    // changes in tables preferences due to api changes:
    //                    - orders table: created -> created_at
    //                    - customers table assesor name change: created -> created_at
    //@ts-expect-error migrations are poorly typed
    2: (state: RootState) => ({
        ...state,
        ordersTablePreference: {
            ...state.ordersTablePreference,
            sortBy: [{ desc: true, id: "created_at" }],
        },
        customersTablePreference: {
            ...state.customersTablePreference,
            columnsOrder: [
                "full_name",
                "external_id",
                "mobile_phone_number",
                "email",
                "payment_method",
                "created",
                "phone_number",
                "home_phone_number",
                "work_phone_number",
                "client_name",
            ],
        },
    }),
    //@ts-expect-error migrations are poorly typed
    3: (state: RootState) => ({
        ...state,
        customersTablePreference: {
            ...state.customersTablePreference,
            columnsOrder: [
                "full_name",
                "external_id",
                "mobile_phone_number",
                "email",
                "payment_method",
                "created_at",
                "phone_number",
                "home_phone_number",
                "work_phone_number",
                "client_name",
            ],
        },
    }),
    //include failed notifications slice
    //@ts-expect-error migrations are poorly typed
    4: (state: RootState) => ({
        ...state,
        failedNotificationsTablePreference: {
            ...state.failedNotificationsTablePreference,
        },
    }),
    //include ui stored orders filters
    //@ts-expect-error migrations are poorly typed
    5: (state: RootState) => ({
        ...state,
        ui: {
            ...state.ui,
            ordersOptionalFilters: [],
        },
    }),
    //Change campaigns hidden columns initial state
    //@ts-expect-error migrations are poorly typed
    6: (state: RootState) => ({
        ...state,
        campaignsTablePreference: {
            ...state.campaignsTablePreference,
            hiddenColumns: ["filename", "description", "uuid"],
        },
    }),
    //include client tree slice
    //@ts-expect-error migrations are poorly typed
    7: (state: RootState) => ({
        ...state,
        clientTree: {
            ...state.clientTree,
        },
    }),
    //change initial columns order add source type after operative
    //@ts-expect-error migrations are poorly typed
    8: (state: RootState) => ({
        ...state,
        ordersTablePreference: {
            ...state.ordersTablePreference,
            columnsOrder: [
                "uuid",
                "client",
                "created_at",
                "amount",
                "status",
                "operative",
                "source_type",
                "service",

                "customer",
                "service_uuid",
                "additional",
            ],
            hiddenColumns: [
                ...state.ordersTablePreference.hiddenColumns,
                "transaction_error",
            ],
        },
    }),
    //add merchant slice
    //@ts-expect-error migrations are poorly typed
    7: (state: RootState) => ({
        ...state,
        merchant: {
            ...state.merchant,
        },
    }),
};
export default migrations;
