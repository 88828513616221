import { createSlice } from "@reduxjs/toolkit";
import { SnackbarSliceStateType } from "../Interfaces/Redux/sliceStateTypes";

const initialState: SnackbarSliceStateType = {
    message: "",
    type: "success",
    isOpen: false,
    persist: false,
};

export const SnackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setSnackbar: (state, action) => {
            state.message = action.payload.message;
            state.isOpen = true;
            state.type = action.payload.type;
            state.persist = action.payload.persist
                ? action.payload.persist
                : false;
        },
        closeSnackbar: (state) => {
            state.isOpen = false;
        },
    },
});
export const { setSnackbar, closeSnackbar } = SnackbarSlice.actions;
export default SnackbarSlice.reducer;
